*, html, body {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.container {
  background-color: #F0E7E6;
  /* background-color: #3B6BFE; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.navbar {
  background: rgba(240,231,230,1) !important;
  box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.1);
}

.fa-facebook-square:hover {
  color: #3B5998;
}

.fa-instagram:hover {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



